import Navbar from "./navbar";

function UnderConstruction() {
    return(
        <div>
            <Navbar/>
            <h1> This page is under construction</h1>
        </div>
    )
}

export default UnderConstruction